.root
  position: relative
  height: auto
  display: flex
  justify-content: space-between
  padding: 15px 8px 15px 0

.title
  font-size: 18px
  font-weight: 500

.arrowButton
  height: auto
  width: fit-content
  padding: 0
  flex-shrink: 0

.arrowIcon
  fill: $black
  transform: rotate(270deg)
  transition: transform 200ms

.arrowIconStateClose
  transform: rotate(90deg)
