html
    height: 100%

body
    -webkit-text-size-adjust: 100%
    height: 100%
    min-width: 320px
    background-color: #fff
    color: #414141
    font: 14px 'PP Object Sans', Arial, Helvetica, Sans-serif

input, select, button
    font: 14px 'PP Object Sans', Arial, Helvetica, Sans-serif


a
    color: #414141
    transition: color .15s, text-decoration-color .15s
    text-decoration: underline
    text-underline-offset: .2em
    text-decoration-color: transparent

    &:hover
        color: #48D597
        text-decoration-color: #aaa
