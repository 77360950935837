.root
  display: block
  background: white
  padding: 15px
  padding-bottom: 35px

.items
  display: none

.displayBlock
  display: block

.optionButton.optionButton
  width: fit-content
  height: auto

.title
  position: relative
  font-size: 18px
  font-weight: 500
  cursor: pointer
  margin-bottom: 15px

  &:after
    position: absolute
    top: 50%
    right: 2px
    width: 8px
    height: 8px
    margin: -7px 0 0
    border-width: 0 1px 1px 0
    border-style: solid
    border-color: $black
    content: ""
    transform: rotate(45deg)
    transition-property: transform
    transition-duration: .35s
    transition-timing-function: ease

.isRotate:after
  margin-top: -2px
  transform: rotate(225deg)

.inputContainer
  margin-bottom: 8px

.lists
  padding: 8px 0

.listItem
  padding: 10px 0

.restButton
  margin-top: 10px
  border-bottom: 1px dashed $grey
  width: auto
  height: auto

.checkbox
  margin-right: 11px

@media ($min-tablet)

  .root
    padding: 0
    margin: 0

  .inputContainer
    margin-bottom: 15px

  .listItem
    padding: 5px 0
