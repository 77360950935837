// ----------------------------------- PP Object Sans

//Regular
@font-face
    font-family: "PP Object Sans"
    src: url("@static/fonts/PPObjectSans-Regular.woff") format("woff"),
        url("@static/fonts/PPObjectSans-Regular.ttf") format("truetype"),
        url("@static/fonts/PPObjectSans-Regular.eot") format("fff")
    font-weight: normal
    font-style: normal
    font-display: swap

//Medium
@font-face
    font-family: "PP Object Sans"
    src: url("@static/fonts/PPObjectSans-Medium.woff") format("woff"),
        url("@static/fonts/PPObjectSans-Medium.ttf") format("truetype"),
        url("@static/fonts/PPObjectSans-Medium.eot") format("fff")
    font-weight: 500
    font-style: normal
    font-display: swap

// ----------------------------------- Roboto

//Regular
@font-face
    font-family: "Roboto"
    src: url("@static/fonts/Roboto-Regular.eot")
    src:
        url("@static/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
        url("@static/fonts/Roboto-Regular.woff2") format("woff2"),
        url("@static/fonts/Roboto-Regular.woff") format("woff"),
        url("@static/fonts/Roboto-Regular.ttf") format("truetype"),
        url("@static/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg")
    font-weight: normal
    font-style: normal
    font-display: swap
