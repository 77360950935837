.root
  display: flex
  flex-direction: column
  min-height: 100vh

.header
  width: 100%
  margin: 0 auto

.main
  display: flex
  flex-direction: column
  flex-grow: 1
  width: 100%
