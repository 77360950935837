.root
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex

.image
  margin: auto
