.banner
  margin: 24px 0

.item
  border: 1px solid $light-grey
  border-radius: 8px
  padding: 16px

  & + &
    margin-top: 16px

.itemPlacementModal
  border: none
  padding: 16px 0

  & + &
    margin-top: 0
  
