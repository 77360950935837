@keyframes skeleton-keyframes-wave
  0%
    background-position: 0 0

  50%
    background-position: -100% 0

  100%
    background-position: -200% 0

.root
  display: block
  clear: both
  line-height: 100%
  border-radius: 2px
  overflow: hidden

  & + &
    margin-top: 10px

.themeGrey
  background: linear-gradient(to right, $warm-grey 0%, $warm-grey 35%, $light-grey 50%, $warm-grey 65%, $warm-grey 100%)
  background-size: 200% 100%
  background-repeat: repeat
  animation: skeleton-keyframes-wave 1500ms linear infinite

.themeWhite
  background-color: $white

.typeText
  &:after
    content: "\00a0"

.typeCircle
  border-radius: 50%

.typeCircle, .typeSquare
  &:after
    content: ""
    display: block
    padding-bottom: 100%

.typeRect
  border-radius: 0
