.root
  display: flex
  flex-direction: column
  width: 100%
  max-width: 344px

.text
  margin-top: 12px

.button
  width: auto
  margin-top: 32px
  align-self: flex-end