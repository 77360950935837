.root
  display: block
  max-width: 1200px
  margin: auto
  padding: 20px 32px 30px 32px
  box-sizing: border-box

.link
  border: none
  display: flex
  align-items: center
  text-decoration: none

  &:hover
    color: $black

.outletLogo
  fill: $black

.iconSpan
  display: inline-block
  margin-left: 20px
  width: 120px
  vertical-align: middle
  font-size: 14px
