.wrapper
  height: 100%
  display: flex
  justify-content: center
  align-items: center

.root
  text-align: center
  padding: 40px

.girlIcon
  width: 160px
  margin-bottom: 20px

.title.title
  margin-bottom: 5px
  font-size: 36px
  margin-top: 0

.action
  width: auto
  margin: 40px auto 0 auto

.buttonsBlock
  display: flex
  margin-top: 10px
  justify-content: center
  align-items: center

.addNewProductButton
  min-width: 110px
  width: fit-content
  margin: 20px 5px 0 5px
