.item
  display: inline-block
  margin: 2px 10px 2px 0

.color.color
  display: block
  width: 20px
  height: 20px
  border-radius: 0
  cursor: pointer
  transition: border-radius 0.3s

  &:hover
    border-radius: 50%

.white
  box-shadow: 0 0 0 2px #f5f0ef inset

.active.active
  border-radius: 50%

.diff
  background: linear-gradient(to right,red 0,#ff8000 10%,#ff0 20%,#80ff00 30%,#0f0 40%,#00ff80 50%,#0ff 60%,#0ff 70%,#00f 80%,#8000ff 90%,#ff0080 100%)

@media ($max-medium)
  .mobile
    .item
      width: 40px
      height: 40px
