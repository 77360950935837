.root
  display: flex
  flex-direction: column
  background-color: #F7F7F7

.content
  flex-grow: 1

.title.title
  font-size: 14px

.list
  margin-top: 16px

.item
  display: flex

  & + &
    margin-top: 8px

.icon
  flex-shrink: 0
  fill: $black

.text
  display: inline-block
  margin-left: 16px
  font-size: 14px
  line-height: 16px

.bold
  font-weight: 500

.button.button
  margin-top: 16px

@media ($min-desktop)
  .root
    flex-direction: row
    align-items: center

  .button.button
    width: fit-content
    margin: 0 48px
