.list
  display: flex
  flex-wrap: wrap
  gap: 8px
  padding: 16px

.button.button
  display: inline-flex
  align-items: center
  width: fit-content
  padding: 8px 16px
  border: 1px solid $light-grey
  border-radius: 8px
  box-sizing: border-box

  &:hover
    color: $black

.buttonStateActive.buttonStateActive
  border-color: $green

.label
  display: inline-block
  margin-left: 8px
  font-size: 14px
  font-weight: 400
  line-height: 18px

@media ($min-tablet)
  .list
    padding: 0

  .button.button
    &:hover
      color: $green
