.modal.modal
  width: 100%
  min-width: 340px
  max-width: 480px
  box-sizing: border-box

.list
  margin-top: 32px

.item
  display: flex
  gap: 16px

  & + &
    margin-top: 16px

.icon
  flex-shrink: 0

.label
  font-size: 14px
  font-weight: 500

.description
  margin-top: 4px
  font-size: 14px
  line-height: 16px

.button.button
  margin: 32px 0 0 auto
  width: fit-content
