.checkbox
  margin-right: 11px

.changeButton
  margin-left: auto
  width: fit-content
  display: block

.modal.modal
  width: 100%
  max-width: 440px

.description
  margin-top: 16px

.citySelect
  margin-top: 16px

.submit.submit
  margin-top: 32px
  margin-left: auto
  width: auto