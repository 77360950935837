undefined {
  .spacing$(i) {
    .item {
      padding: 20px 8px
        }

    .innerContainer {
      min-width: 100%;
      margin-left: -$(i)px;
      margin-right: -$(i)px;
      display: flex;
      flex-wrap: wrap
        }
    }
}