.list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 8px

.item
  padding: 8px
  border-radius: 8px
  border: 1px solid #D2D2D2
  box-sizing: border-box
  min-width: 0

.button.button
  display: block
  font-size: 14px
  font-weight: 400
  line-height: 16px
  text-align: center
  color: #8E8E8E

.primaryName
  display: block
  color: $black

.primaryName, .secondaryName
  display: block
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.buttonStateActive.buttonStateActive
  color: $green
  font-weight: 500

  .primaryName
    color: $green
