.root
  display: flex
  justify-content: space-between
  align-items: center

  & + &
    margin-top: 24px

.deliveryInfo
  display: flex

.deliveryIcon
  width: 20px
  height: 20px
  margin-right: 10px
  flex-shrink: 0

.deliveryName
  color: $black
  margin-bottom: 5px
  font-weight: 500

.deliveryCurrency
  font-size: 14px
  color: $tip-color
  margin-bottom: 10px

.descriptionLine
  margin-bottom: 3px

.isHiddenDescription
  margin-top: 16px
  color: $brown-gray

.switch
  flex-shrink: 0
  margin-left: 16px

.themeFull

  &.root
    border: 1px solid #eee
    border-radius: 8px
    padding: 16px

    & + &
      margin-top: 16px

