.root
  display: flex
  flex-direction: column
  position: relative
  padding: 16px 0

.productInfoGroup
  display: flex

  // For trash icon
  &.withPadding
    padding-right: 40px

.productImage
  width: 80px
  height: auto
  border-radius: 16px

.productInfo
  margin-left: 16px

.brand
  font-weight: 500

.name
  margin-top: 4px

.size
  margin-top: 8px
  font-weight: 500

.basketProductAttributes
  display: flex
  justify-content: space-between
  margin-top: 16px

.attribute
  text-align: right

.attributeName
  font-size: 12px
  color: #8E8E8E

.attributeValue
  margin-top: 8px
  font-weight: 500
  min-width: 75px

.productsCount
  display: flex
  justify-content: space-between
  align-items: center
  margin-right: -8px

.countControll.countControll
  font-size: 24px
  font-weight: 400
  color: #48d597
  cursor: pointer
  width: 28px
  height: 28px

  &:disabled
    color: #414141

.oldPrice
  font-size: 12px
  color: #8E8E8E
  text-decoration-line: line-through

.trashIcon
  position: absolute
  right: 0
  top: 0
  cursor: pointer
  width: 24px
  height: 24px
  padding: 8px
  border-radius: 100%

@media ($min-desktop)
  .root
    justify-content: space-between
    flex-direction: row

    &.withPadding
      padding-right: 72px

  .basketProductAttributes
    width: 300px
    flex-shrink: 0

  .trashIcon
    top: 32px
