.root
  display: flex
  justify-content: space-between
  align-items: center

  &.hidden
    opacity: .5

.info
  display: flex
  align-items: center
  flex: 1
  margin-right: 5px

.block
  padding: 0 8px

.name
  color: $black
  font-weight: 500

.description
  display: inline-block
  margin-top: 4px
  font-size: 14px
  color: $tip-color

.prepayment
  margin-top: 4px
  color: $brown-gray

.editButton.editButton
  width: fit-content
  height: auto
  margin-top: 8px
  color: $green
