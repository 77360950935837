.root.root
  display: grid
  background-color: #F3F1FE
  grid-template-columns: 32px 1fr
  align-items: center
  grid-gap: 16px

.description
  font-size: 14px
  line-height: 16px

.bold
  font-weight: 500

.button.button
  width: fit-content
  grid-column-start: 2
