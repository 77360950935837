.root
  display: inline-flex
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: $purple

.rootSize72
  width: 72px
  height: 72px

  .icon
    width: 56px
    height: 56px

.rootSize56
  width: 56px
  height: 56px

  .icon
    width: 40px
    height: 40px

.rootSize40
  width: 40px
  height: 40px

  .icon
    width: 32px
    height: 32px

.rootSize32
  width: 32px
  height: 32px

  .icon
    width: 24px
    height: 24px

.rootSize24
  width: 24px
  height: 24px

  .icon
    width: 20px
    height: 20px

.rootSize18
  width: 18px
  height: 18px

  .icon
    width: 16px
    height: 16px
