.root
    display: flex
    padding-top: 40px

.imageWrapper
    flex-basis: 50%

.image
    width: 100%
    height: 100%
    background: $warm-grey

.title
    height: 44px
    background: $warm-grey
    width: 50%
    margin-bottom: 24px

.aside
    margin-left: 20px
    width: 100%

.imagePreloader
    width: 60px
    height: 60px
    fill: $grey-hover
    margin: auto

.itemsWrapper
    width: 100%
    display: flex

.item
    display: flex
    width: 25%
    height: 249px
    margin: 10px
    box-sizing: border-box
    background-color: $warm-grey
    border-radius: 32px


@media ($max-desktop)
    .root
        display: none
