.input
  width: 100%

.label
  margin-bottom: 8px

.helpText
  font-size: 12px
  margin-top: 8px
  color: #8e8e8e

.invalid
  .input
    border-color: #E65D50
