.root
  width: 250px
  flex: none

.title
  display: block
  font-size: 24px
  line-height: 30px
  padding: 15px 0 20px 0
  font-weight: 400

.cross
  display: none

.loading
  margin-top: 20px
  display: block
  flex: none
  width: 250px
  height: 350px
  background: $warm-grey

.buttonSeeFilters
  width: 96%
  position: fixed
  bottom: 0
  margin: 8px

.clearAllBlock.clearAllBlock
  padding: 10px 10px 10px 8px
  display: block

.clearAll
  padding: 8px
  display: flex
  justify-content: center
  color: $pastel-red
  border-color: $pastel-red
  border: 1px solid
  border-radius: 8px
  cursor: pointer
  text-decoration: none

  &:hover
    color: $pastel-red
    border-color: $pastel-red
    text-decoration: none

.catalogs
  margin-bottom: 24px

.section
  //


@media ($max-medium)
  .loading
    display: none
  .root
    display: none

  .cross
    display: inline-block
    fill: $black
    width: 16px
    height: 16px
    margin-right: 10px
    cursor: pointer

  .activeFilter
    display: block
    background: white
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    width: 100%
    box-sizing: border-box
    padding: 0
    overflow: auto
    padding-bottom: 60px
    z-index: $mobile-feed-filters-z-index

    .title
      margin-left: 20px

    & > div
      padding: 0 20px
      border-top: 8px solid $warm-grey
      padding-bottom: 10px
  
  .section
    padding: 10px
    border-top: 8px solid $warm-grey

  .catalogs
    margin-bottom: 0
