.input
  margin-top: 5px
  transition: border-color 0.3s

  &:hover *:before
    border-color: $green

.textInput
  margin-left: 10px

@media ($max-medium)
  .mobile
    font-size: 16px

    .input
      margin-top: 5px
