.root
  width: 360px
  display: flex
  justify-content: center
  text-align: center
  flex-direction: column

.iconHolder
  display: flex
  justify-content: center
  align-items: center
  width: 72px
  height: 72px
  border-radius: 50%
  background-color: $green-100
  fill: $black
  margin: auto

.title
  margin: 24px 0 16px 0

.text
  margin-bottom: 16px

.boldText
  font-weight: 500

.closeButton
  margin-top: 26px

.buttons
  margin-top: 16px

.nextReviewRequest
  width: max-content
  margin: auto
