.modal.modal
  width: 100%
  max-width: 480px

.title
  font-size: 24px
  font-weight: 500
  line-height: 32px

.description
  margin-top: 16px
  font-size: 14px
  line-height: 16px

.form
  margin-top: 16px

.footer
  margin-top: 32px
  display: flex
  flex-wrap: wrap
  justify-content: flex-end
  align-items: center
  gap: 8px

.button.button
  width: fit-content
