.switchLabel
  display: inline-block
  cursor: pointer
  width: 40px
  height: 20px
  background-color: $grey
  border-radius: 100px
  position: relative
  transition: background-color .2s

.switchLabel:active .switchButton
  width: 30px

.switchButton
  position: absolute
  top: 2px
  left: 2px
  width: 16px
  height: 16px
  border-radius: 45px
  transition: .2s
  background-color: $white
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29)

.active
  background: $green

  .switchButton
    left: calc(100% - 2px)
    transform: translateX(-100%)

.disabled
  opacity: .5
  cursor: default
