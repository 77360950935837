.root
  display: flex
  border: 1px solid #eee
  border-radius: 8px
  justify-content: space-between
  padding: 16px
  align-items: center

.leftSide
  display: flex
  flex-direction: column
  margin-right: 16px

.title
  color: $black
  margin-bottom: 10px
  font-weight: 500

.description
  font-size: 14px
  color: $tip-color

.switch
  flex-shrink: 0