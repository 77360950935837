.root.root
  width: 100%
  max-width: 311px
  box-sizing: border-box

.actionButtons
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-top: 32px
  gap: 16px

.actionButton.actionButton
  width: 100%

@media ($min-medium)
  .actionButtons
    flex-wrap: wrap
    flex-direction: row
    justify-content: flex-end
    gap: 8px

  .actionButton.actionButton
    width: fit-content

  .root.root
    width: 480px
    max-width: 480px
