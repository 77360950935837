.mobileMenuHeader
  height: 40px
  padding: 16px
  margin-bottom: 4px
  box-sizing: border-box
  display: flex
  justify-content: space-between
  align-items: center
  background-color: $very-light-pink
  font-weight: 500

.closeIconOuter
  box-sizing: border-box
  width: 24px
  height: 24px
  padding: 5px

.closeIcon
  width: 100%
  height: 100%


@media ($min-tablet)

  .mobileMenuHeader
    display: None
