@media ($max-tablet)

  // Mobile specific styles, should be under media rule
  // Because for tablet+ screen sizes `react-select` provide
  // it's own styles
  .root.root
    top: unset
    bottom: 0
    margin-bottom: 0
    margin-top: 0

  .appear
    transform: translateY(100%)

  .appearActive
    transform: translateY(0)
    transition: transform .3s
