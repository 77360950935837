.mobilePortal
  z-index: $select-mobile-portal-z-index
  background-color: rgba(0, 0, 0, .4)
  top: 0
  bottom: 0
  left: 0
  right: 0
  position: fixed

.appear
  background-color: transparent

.appearActive
  background-color: rgba(0, 0, 0, .4)
  transition: background-color .3s
