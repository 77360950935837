.subFilter
  padding-left: 20px

.link.link
  display: inline-block
  cursor: pointer
  text-decoration: none
  font-weight: 500
  padding: 7px 16px
  border-radius: 8px
  width: 100%
  box-sizing: border-box

  &:hover
    text-decoration: none
    background-color: #f7f7f7

.activeLink
  color: $black
  text-decoration: none
  background: #EEEEEE

.count
  color: $grey
  font-size: 12px
  margin-left: 8px
  font-weight: 400
