.label
  display: flex
  align-items: center
  font-size: 0

.title
  font-size: 18px
  font-weight: 500

.icon
  flex-shrink: 0
  margin-right: 4px

.iconButton.iconButton
  height: auto
  width: auto
  padding: 4px
  margin-left: 8px

  svg
    fill: $brown-gray
