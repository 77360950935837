.root
  display: flex
  flex-direction: column
  align-items: flex-end
  width: 100%

.overlay
  background-color: #fff

.title
  margin-bottom: 32px
  text-align: left
  width: 100%

.settingBlock
  width: 100%
  margin-bottom: 24px


.workingStatus
  padding: 0
  border: 0
  margin-top: 16px

.settingTitle
  font-weight: 500
  margin-bottom: 16px

.notification
  margin-bottom: 24px
  color: $brownish-grey


@media ($min-tablet)
  .root
    max-width: 420px
