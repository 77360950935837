.holder
  margin-top: 16px
  max-height: 600px
  overflow-y: auto

.list, .item
  & + &
    margin-top: 16px

.group
  margin-bottom: 16px
  font-weight: 500
  font-size: 16px
  line-height: 24px

.optionButton.optionButton
  width: fit-content
  height: auto

.checkbox
  transition: border-color 0.3s

  &:hover *:before
    border-color: $green

.label
  margin-left: 10px

.labelTypeBold
  font-weight: 500

.ua::after
  content: ""
  display: block
  margin-left: 8px
  width: 16px
  height: 16px
  border-radius: 1px
  background: linear-gradient(180deg, #0057b8 50%, #ffd800 50%)

.button.button
  margin-top: 16px
  color: #8E8E8E
  border-bottom: 1px dashed
  border-radius: 0
  width: fit-content
  height: auto

@media ($min-medium)
  .holder
    margin-top: 14px

  .list, .item
    & + &
      margin-top: 8px

  .group
    margin-bottom: 8px
