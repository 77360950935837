.products
  margin-top: 8px

.product
  &:not(:first-child)
    border-top: 1px solid #eee

  &:last-child
    border-bottom: 1px solid #eee

.smartBanner
  margin-top: 16px

.buttons
  display: flex
  flex-direction: column
  gap: 16px
  margin-top: 16px

.button.button
  width: 100%

@media ($min-tablet)
  .buttons
    flex-direction: row
    justify-content: flex-end

  .button.button
    width: auto


// Preloader
.productPreloader
  height: 144px
  width: 100%
  background-color: #eee
  border-radius: 8px

  &:not(:first-child)
    margin-top: 8px

.buttonPreloader
  width: 300px
  max-width: 100%
  height: 40px
  background-color: #eee
  border-radius: 8px
  margin-top: 16px
  margin-left: auto
