.appear,
.enter
  opacity: 0
  transform: translateY(5px)

.appearActive,
.enterActive,
.enterDone
  opacity: 1
  transform: translateY(0)
  transition: .2s opacity ease-in, .2s transform ease-in