.root
  display: flex
  align-items: center
  cursor: pointer

.checkbox
  display: none

.checkboxOuter
  position: relative
  cursor: pointer
  user-select: none
  height: 16px
  width: 16px
  margin: 0

  &:before
    position: absolute
    width: 14px
    height: 14px
    top: 0
    left: 0
    border: 1px solid $input-border-color
    background-color: $white
    transition: border-color .15s linear, opacity .15s linear
    content: ""

  &:after
    position: absolute
    left: 3px
    top: 9px
    width: 3px
    height: 8px
    border-width: 2px 2px 0 0
    border-style: solid
    border-color: $white
    transform-origin: left top
    transform: scaleX(-1) rotate(135deg) skewX(-5deg)
    transition: opacity .15s linear
    opacity: 0
    content: ""

.checked

  &:before
    background-color: $green
    border-color: $green

  &:after
    opacity: 1
