.root
  z-index: $shafa-loader-z-index
  display: none
  justify-content: center
  align-items: center
  opacity: 0
  transition: opacity .2s

.enter
  display: flex
  position: absolute
  z-index: -1
  visibility: hidden

.enterActive
  display: flex
  opacity: 1

.exitActive
  // Exit without delays
  transition-delay: unset
  display: flex
  opacity: 0

.fullScreen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0

.shadowOverlay
  background-color: rgba(0, 0, 0, 0.4)

.image
  border-radius: 2px