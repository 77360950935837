.link

  // Common styles across all themes except 'clean' theme
  &:not(.themeClean)
    cursor: pointer
    transition: color .15s, text-decoration-color .15s
    text-decoration: underline
    text-decoration-color: transparent


// Theme specific styles
.themeGreen
  color: $green

  &:hover
    color: $link-hover-color
    text-decoration-color: $link-hover-color

  &.active
    color: $text-color

.themeBlack
  color: $black

  &:hover
    color: $link-hover-color
    text-decoration-color: $link-hover-color

  &.active
    color: $green

.themeDotted.themeDotted
  color: $black
  text-decoration-style: dotted
  text-decoration-color: #cdcdcd

  &:hover
    color: $link-hover-color
    text-decoration-color: $link-hover-color

// Theme without specific styles, can be used to create custom link
.themeClean
  // Override default anchor styles
  text-decoration: none

  &:hover
    text-decoration: none
    color: $black
