.root
  display: flex
  flex-direction: column

.text
  margin-top: 12px

.button
  width: auto
  margin-top: 32px
  align-self: flex-end