.checkbox
  margin-top: 5px
  transition: border-color 0.3s

  &:hover *:before
    border-color: $green

.checkboxInput
  margin-left: 10px

.inputsWrapper
  display: flex
  margin-top: 10px
  justify-content: space-between

.fromPrice.fromPrice
  padding-right: 5px
  width: 48%

.inputPrice.inputPrice
  width: 48%

@media ($max-medium)
  .mobile
    .inputsWrapper
      font-size: 16px
      margin-top: 15px
