.item
  display: inline-block
  box-sizing: border-box
  width: 100%

@each $i in 0, 10
  .spacing$(i)
    .item
      padding: 20px 8px

    .innerContainer
      min-width: 100%
      margin-left: -$(i)px
      margin-right: -$(i)px
      display: flex
      flex-wrap: wrap

@for $i from 1 to 10

  .default$(i)
    width: $i / 12 * 100%

@media ($min-tablet)
  @for $i from 1 to 10

    div.tablet$(i)
      width: $i / 12 * 100%

@media ($min-desktop)
  @for $i from 1 to 10
    div.desktop$(i)
      width: $i / 12 * 100%

  div.desktop2_4
    width: 20%
