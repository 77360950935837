.root
  position: relative
  display: flex
  flex-direction: column

.content
  display: flex
  flex-grow: 1
  flex-direction: column

.sellerGroup
  padding: 16px 0

.emptyState
  flex-grow: 1
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.basketIcon
  width: 48px
  height: 48px

.emptyStateTitle
  margin-top: 36px

.emptyStateDescription
  margin-top: 16px

.toPurchasesButton.toPurchasesButton
  position: absolute
  bottom: 16px
  width: calc(100% - 32px)
  transform: translateX(-50%)
  left: 50%

@media ($min-tablet)

  .root.root
    width: 700px
    min-height: 540px

  .toPurchasesButton.toPurchasesButton
    position: static
    width: fit-content
    margin-top: 32px
    transform: none


@media ($min-desktop)

  .root.root
    width: 960px
    min-height: 700px
