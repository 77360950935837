.photoHolder
  position: relative
  width: 100%
  height: 235px
  border-radius: 16px
  overflow: hidden

.label
  position: absolute
  top: 10px
  left: 10px

.content
  margin-top: 10px

@media ($min-desktop)
  .photoHolder
    height: 290px
    border-radius: 32px
