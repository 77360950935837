.tag
  height: 28px
  margin: 2px
  display: inline-flex
  align-items: center
  border: 1px solid $grey-hover
  background: $warm-grey
  border-radius: 4px

.tagText
  margin: 0 12px
  display: inline-block
  vertical-align: middle

.tagButton
  height: 100%
  width: 12px
  margin: 0 12px
  display: flex
  align-items: center
  vertical-align: middle
  cursor: pointer
