.root
  box-sizing: border-box
  padding: 16px
  border-radius: 8px

.themeInfo
  background-color: $green-100

.themeWarning
  background-color: $yellow-100

.themeDefault
  background-color: $light-grey
