.buttons
  display: flex
  justify-content: flex-end
  margin-top: 32px

.button.button
  width: auto

  & + &
    margin-left: 8px


@media ($min-tablet)

  .root.root
    width: 420px
